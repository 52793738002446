import groq from 'groq';
import { SanityKeyed } from '../schemaTypes';
import { PortableTextBlockComponent } from '@portabletext/react';
import {
  MaterializedInternalLink,
  internalLinkProjection,
} from './internalLink';

export type MaterializedPortableText = (PortableTextBlockComponent &
  SanityKeyed<{
    marks: (
      | unknown
      | {
          internalLink?: MaterializedInternalLink | null;
        }
    )[];
  }>)[];

export const portableTextProjection = groq`
  ...,
  markDefs[]{
    ...,
    _type == "internalLinkMark" => {
      ...,
      internalLink {
        ${internalLinkProjection}
      }
    }
  }
`;
