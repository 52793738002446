import groq from 'groq';
import { SanityKeyed, MemberCard } from '../../schemaTypes';
import { imageWithCropProjection } from '../../materializedTypes';

export type MaterializedMemberCard = SanityKeyed<
  MemberCard & {
    mainImage?: {
      croppedImageUrl?: string;
      alt?: string;
    };
  }
>;

export const memberCardProjection = groq`
  ...,
  mainImage {
    ${imageWithCropProjection}
  }
`;
