import {
  bodyProjection,
  MaterializedBody,
  ProductEnrichment,
} from '@fagmobler/sanity';
import groq from 'groq';
const productEnrichmentProjection = groq`
  ...,
  body[] {
    ${bodyProjection}
  }
`;

export const productEnrichmentQuery = groq`*[
  _type == "productEnrichment" && productSKU == $productSKU
] {
  ${productEnrichmentProjection}
}[0]`;
export const productEnrichmentPreviewQuery = groq`*[
  _id == $id
] {
  ${productEnrichmentProjection}
}[0]`;

export type MaterializedProductEnrichment = ProductEnrichment & {
  body: MaterializedBody | null;
};
