import { Video } from '../../schemaTypes';
import groq from 'groq';

export type MaterializedVideo = Video & {
  playbackId?: Video['muxVideo']['asset']['playbackId'];
  thumbTime?: Video['muxVideo']['asset']['thumbTime'];
};

export const videoProjection = groq`
  ...,
  "playbackId": muxVideo.asset->playbackId,
  "thumbTime": muxVideo.asset->thumbTime
`;
