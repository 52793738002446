import { ImageAndText } from '../../schemaTypes';
import {
  MaterializedImageWithCrop,
  imageWithCropProjection,
} from '../../materializedTypes';
import groq from 'groq';

export type MaterializedImageAndTextBlock = ImageAndText & {
  mainImage?: MaterializedImageWithCrop;
};

export const imageAndTextProjection = groq`
  ...,
  mainImage {
    ${imageWithCropProjection}
  }
`;
