import groq from 'groq';
import { ImageWithPoints } from '../schemaTypes';
import { imageWithCropProjection, MaterializedImageWithCrop } from './image';

export type MaterializedImageWithPoints = Omit<ImageWithPoints, 'image'> & {
  image?: MaterializedImageWithCrop;
};

export const imageWithPointsProjection = groq`
  ...,
  image {
    ${imageWithCropProjection}
  }
`;
