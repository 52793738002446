import groq from 'groq';
import { ContentBlocksGrid, SanityKeyed } from '../../schemaTypes';
import { MaterializedArticleCard, articleCardProjection } from './articleCard';
import {
  MaterializedCompaignCard,
  campaignCardProjection,
} from './campaignCard';
import { campaignCardV2Projection } from './campaignCardV2';

export type MaterializedContentBlocksGrid = ContentBlocksGrid & {
  content: SanityKeyed<MaterializedCompaignCard | MaterializedArticleCard>[];
};

export const contentBlocksGridProjection = groq`
  ...,
  content[] {
    _type == "articleCard" => {${articleCardProjection}},
    _type == "campaignCard" => {${campaignCardProjection}},
    _type == "campaignCardV2" => {${campaignCardV2Projection}},
  }
`;
