import groq from 'groq';
import type { ReusableContentBlock } from '../schemaTypes';
import {
  MaterializedContentBlock,
  contentBlockProjection,
} from './contentBlocks';

export type MaterializedReusableContentBlock = ReusableContentBlock & {
  contentBlocks: MaterializedContentBlock[];
};

export const reusableContentBlocksProjection = groq`
  ...,
  contentBlocks[] {
    ${contentBlockProjection}
  }
`;

export const reusableContentBlockQuery = groq`
  *[_id == $id && _type == 'reusableContentBlock'] {
    ${reusableContentBlocksProjection}
  }[0]
`;
