import groq from 'groq';
import { SanityKeyed, ProductCategoryEnrichment } from '../../schemaTypes';

export type MaterializedCategoryCollectionCard = SanityKeyed<{
  _type: 'categoryCollection';
  categories?: SanityKeyed<{
    title?: string;
    subtitle?: string;
    productCategoryEnrichment?: {
      _id: string;
      algoliaProductCategorySlug?: ProductCategoryEnrichment['algoliaProductCategorySlug'];
      icon?: {
        asset?: {
          url: string;
        };
      };
    };
  }>[];
}>;

export const contentBlockCategoryCollection_productCategoryEnrichmentProjection = groq`
  _id,
  title,
  algoliaProductCategorySlug,
  icon {
    asset-> {url}
  }
`;

export const categoryCollectionProjection = groq`
  categories[] {
    _key,
    title,
    subtitle,
    "productCategoryEnrichment": productCategoryEnrichmentReference-> {
      ${contentBlockCategoryCollection_productCategoryEnrichmentProjection}
    }
  }
`;
