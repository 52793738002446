import groq from 'groq';
import {
  MaterializedImageWithPoints,
  imageWithPointsProjection,
} from '../materializedTypes';
import { ImageWithPointsDocument } from '../schemaTypes';

export type MaterializedImageWithPointsDocument = ImageWithPointsDocument & {
  imageWithPoints?: MaterializedImageWithPoints;
};

export const imageWithPointsDocumentProjection = groq`
  ...,
  imageWithPoints {
    ${imageWithPointsProjection}
  }
`;
