import groq from 'groq';
import { bodyProjection, MaterializedBody } from '../../documents/body';
import {
  SimpleProductCategoryEnrichment,
  simpleProductCategoryEnrichmentMaterialization,
} from './productCategoryEnrichment';

export type MaterializedCampaignTab = {
  title: string;
  subtitle?: string;
  icon?: string;
  tabSlug: string;
  body?: MaterializedBody | null;
  autoGenerate?: boolean;
  productCategory?: SimpleProductCategoryEnrichment[] | null;
  blacklistedProducts?: string[];
};

export const campaignTabProjection = groq`
  ...,
  "tabSlug": tabSlug.current,
  "productCategory": productCategoryEnrichmentReference-> {
      ${simpleProductCategoryEnrichmentMaterialization}
    }
`;
