import { Serie } from '../schemaTypes';
import groq from 'groq';
import { bodyProjection, MaterializedBody } from './body';

export type MaterializedSeriePage = Serie & {
  body: MaterializedBody;
};

export const serieProjection = groq`
  {
    ...,
    body[] {
      ${bodyProjection}
    }
  }`;
