import {
  MaterializedInternalLink,
  MaterializedImageWithCrop,
  imageWithCropProjection,
  internalLinkProjection,
} from '../../materializedTypes';
import { SanityKeyed, ContentCard } from '../../schemaTypes';
import groq from 'groq';

export type MaterializedContentCard = SanityKeyed<
  ContentCard & {
    internalLink?: MaterializedInternalLink;
    mainImage?: MaterializedImageWithCrop;
  }
>;

export const contentCardProjection = groq`
  ...,
  internalLink {${internalLinkProjection}},
  mainImage {${imageWithCropProjection}}
`;
