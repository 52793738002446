import groq from 'groq';
import {
  MaterializedInternalLink,
  MaterializedImageWithCrop,
  internalLinkProjection,
  imageWithCropProjection,
} from '../../materializedTypes';
import { SanityKeyed, CampaignCard } from '../../schemaTypes';

export type MaterializedCompaignCard = SanityKeyed<
  CampaignCard & {
    internalLink?: MaterializedInternalLink;
    mainImage?: MaterializedImageWithCrop;
  }
>;

export const campaignCardProjection = groq`
  ...,
  internalLink {${internalLinkProjection}},
  mainImage {${imageWithCropProjection}}
`;
