import { populateBlocksWithProductUrls } from './blocks';
import { MaterializedProductCategoryEnrichment } from '@fagmobler/sanity';

export type MaterializedAndPopulatedProductCategoryEnrichment =
  MaterializedProductCategoryEnrichment;

export const populateProductCategoryEnrichmentWithProductUrls = async (
  category: MaterializedProductCategoryEnrichment
) => {
  return {
    ...category,
    contentBlocks: await populateBlocksWithProductUrls(category.contentBlocks),
    priorityContentBlocks: await populateBlocksWithProductUrls(
      category.priorityContentBlocks
    ),
  } as MaterializedAndPopulatedProductCategoryEnrichment;
};
