import groq from 'groq';
import {
  MaterializedInternalLink,
  MaterializedImageWithCrop,
  internalLinkProjection,
  imageWithCropProjection,
} from '../../materializedTypes';
import { SanityKeyed, CampaignCardV2 } from '../../schemaTypes';

export type MaterializedCompaignCardV2 = SanityKeyed<
  CampaignCardV2 & {
    internalLink?: MaterializedInternalLink;
    mainImage?: MaterializedImageWithCrop;
  }
>;

export const campaignCardV2Projection = groq`
  ...,
  internalLink {${internalLinkProjection}},
  mainImage {${imageWithCropProjection}}
`;
