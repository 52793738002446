import { MaterializedArticlePage, Page } from '@fagmobler/sanity';
import {
  MaterializedContentBlockWithUrl,
  populateBlocksWithProductUrls,
} from './blocks';

export const populateArticlePageWithUrls = async (
  articlePage: MaterializedArticlePage
) => {
  const bodyWithUrls =
    articlePage?.body &&
    (await populateBlocksWithProductUrls(
      articlePage.body as MaterializedContentBlockWithUrl[]
    ));
  return {
    ...articlePage,
    body: bodyWithUrls || articlePage.body || null,
  } as MaterializedArticlePage;
};

export type MaterializedPage = Page;
