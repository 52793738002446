import type { InternalLink } from '../schemaTypes';
import groq from 'groq';

export type MaterializedInternalLink = InternalLink & {
  page?: {
    _id: string;
    category?: string | null;
    slug?: {
      current?: string;
    } | null;
  } | null;
  file?: {
    asset?: {
      url?: string | null;
    } | null;
  } | null;
};

export const internalLinkProjection = groq`
  ...,
  page-> {
    _id,
    category,
    slug {
      current
    }
  },
  file {
    asset-> {
      url
    }
  }
`;
