import {
  MaterializedInternalLink,
  internalLinkProjection,
} from '../../materializedTypes';
import { SanityKeyed, TopBanner } from '../../schemaTypes';
import groq from 'groq';

export type MaterializedTopBanner = SanityKeyed<
  TopBanner & {
    internalLink?: MaterializedInternalLink;
  }
>;

export const topBannerProjection = groq`
  ...,
  internalLink {${internalLinkProjection}},
`;
