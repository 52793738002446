import groq from 'groq';
import { SanityKeyed, ProductCollection } from '../../schemaTypes';
import type { FagmoblerAlgoliaProduct } from '@fagmobler/types';

export type MaterializedProductCollectionBlock = SanityKeyed<
  ProductCollection & {
    // Should be array of strings, and then fetched from algolia at a later stage (not in sanity  projection)
    products?: FagmoblerAlgoliaProduct[];
  }
>;

export const productCollectionProjection = groq`
  ...
`;
