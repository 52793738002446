import {
  MaterializedImageWithCrop,
  imageWithCropProjection,
} from '../../materializedTypes';
import { ProductCategoryEnrichment } from '../../schemaTypes';
import groq from 'groq';

export type SimpleProductCategoryEnrichment = Pick<
  ProductCategoryEnrichment,
  | '_type'
  | '_id'
  | 'title'
  | 'omniumId'
  | 'description'
  | 'algoliaProductCategorySlug'
  | 'topLevelOmniumId'
  | 'hidden'
> & {
  mainImage: MaterializedImageWithCrop;
};
export const simpleProductCategoryEnrichmentMaterialization = groq`
  _type,
  _id,
  title,
  omniumId,
  description,
  algoliaProductCategorySlug,
  topLevelOmniumId,
  hidden,
  mainImage {
    ${imageWithCropProjection}
  }
`;
