// We only save product SKU on internalLink.

import { FagmoblerAlgoliaProduct } from '@fagmobler/types';
import {
  MaterializedBody,
  MaterializedContentBlock,
  MaterializedImageWithPointsDocument,
  MaterializedInternalLink,
} from '@fagmobler/sanity';
import {
  MaterializedImageWithPointsWithProducts,
  populateBlockWithProducts as populateBlockWithProducts,
  populateImageWithPointsWithProducts,
} from '@/components/contentBlocks/ContentBlocksRenderer';
import { getCanonicalPath } from '@/lib/algolia/getCanonicalPath';
import { getCatgories } from '@/lib/categories/getCategories';

export type MaterializedContentBlockWithUrl = MaterializedContentBlock & {
  internalLink?: MaterializedInternalLink & {
    generatedUrl?: string;
  };
};

export type MaterializedImageWithPointsDocumentWithProducts =
  MaterializedImageWithPointsDocument & {
    imageWithPoints: MaterializedImageWithPointsWithProducts;
  };

// Lets get the algoliaProduct from algolia
export const populateBlocksWithProductUrls = async (
  blocks?: MaterializedContentBlock[]
) => {
  const { ALGOLIA_INDEX_NAME, searchClient } = await import(
    '@/lib/algolia/searchClient'
  );
  const index = searchClient.initIndex(ALGOLIA_INDEX_NAME);

  const promises: Promise<
    | MaterializedContentBlockWithUrl
    | MaterializedImageWithPointsDocumentWithProducts
    | MaterializedBody[0]
  >[] =
    blocks?.map(async (block) => {
      if (
        (block._type === 'campaignCard' ||
          block._type === 'articleCard' ||
          block._type === 'contentCard') &&
        block.internalLink?.productSKU
      ) {
        let generatedUrl: string | undefined = undefined;

        if (block.internalLink?.productSKU) {
          generatedUrl = await index
            .getObject<FagmoblerAlgoliaProduct>(
              block.internalLink?.productSKU,
              {
                analyticsTags: ['storefront', 'populateBlocksWithProductUrls'],
              }
            )
            .then((algoliaProduct) => {
              return getCanonicalPath(algoliaProduct);
            });
        }
        const materializedContentBlockWithUrl = {
          ...block,
          internalLink: {
            ...block.internalLink,
            generatedUrl,
          },
        } as MaterializedContentBlockWithUrl;
        return materializedContentBlockWithUrl;
      }

      // this is currently done client side where the productcollectioncard is rendered
      if (block._type === 'productCollection') {
        const products = await populateBlockWithProducts(block);
        const materializedContentBlockWithUrl = {
          ...block,
          products,
        } as MaterializedContentBlockWithUrl;
        return materializedContentBlockWithUrl;
      }

      if (block._type === 'imageWithPointsDocument' && block.imageWithPoints) {
        const populatedPoints = await populateImageWithPointsWithProducts(
          block.imageWithPoints
        );
        const imageWithPointsDocumentWithProducts = {
          ...block,
          imageWithPoints: populatedPoints,
        } as MaterializedImageWithPointsDocumentWithProducts;

        return imageWithPointsDocumentWithProducts;
      }

      if (block._type === 'reusableContentBlock' && block.contentBlocks) {
        const materializedContentBlockWithUrl = {
          ...block,
          contentBlocks: await populateBlocksWithProductUrls(
            block.contentBlocks
          ),
        } as MaterializedContentBlockWithUrl;
        return materializedContentBlockWithUrl;
      }

      if (block._type === 'productCategoryEnrichment') {
        return {
          ...block,
          categories: await getCatgories(block.omniumId || 'ROM'),
        };
      }

      return block;
    }) || [];
  return await Promise.all(promises);
};
