import groq from 'groq';
import {
  internalLinkProjection,
  imageWithCropProjection,
  MaterializedInternalLink,
  MaterializedImageWithCrop,
} from '../../materializedTypes';
import { SanityKeyed, FullWidthCampaignBanner } from '../../schemaTypes';

export type MaterializedFullWidthCampaignBanner = SanityKeyed<FullWidthCampaignBanner & {
  internalLink?: MaterializedInternalLink;
  mainImage?: MaterializedImageWithCrop;
}>;

export const fullWidthCampaignBannerProjection = groq`
  ...,
  title,
  internalLink {${internalLinkProjection}},
  mainImage {${imageWithCropProjection}}
`;
