import groq from 'groq';

import type {
  ArticleCard,
  Body,
  CampaignTabs,
  ContentCard,
  ImageAndText,
  ImageCollection,
} from '../schemaTypes';

import type { MaterializedImageWithCrop } from '../materializedTypes/image';
import type { MaterializedContentBlock } from '../blocks';
import { contentBlockProjection } from '../blocks/contentBlocks/contentBlock';
import { reusableContentBlocksProjection } from '../blocks/reusableContentBlock';
import { imageWithPointsDocumentProjection } from './imageWithPointsDocument';
import {
  MaterializedPortableText,
  portableTextProjection,
} from '../materializedTypes/portableText';
import { PortableTextBlock } from '@portabletext/types';

export type BodyImageCollection = Omit<ImageCollection, 'images'> & {
  images: (MaterializedImageWithCrop & {
    _key: string;
  })[];
};

export type BodyImageAndText = Omit<ImageAndText, 'mainImage'> & {
  mainImage: MaterializedImageWithCrop;
};

export type MaterializedBody = (PortableTextBlock &
  (Body[] &
    (
      | MaterializedPortableText
      | MaterializedContentBlock
      | BodyImageCollection
      | ArticleCard
      | ContentCard
      | MaterializedImageWithCrop
      | BodyImageAndText
      | CampaignTabs
    )))[];

export const bodyProjection = groq`
  ${portableTextProjection},
  ${contentBlockProjection},
  _type == "campaignTabs" => {
    ...,
    tabs[] {
      ...,
      productCategoryEnrichmentReference -> {
        algoliaProductCategory,
      }
    }
  },
  _type == "referenceToReusableContentBlock" => {
    ...
  }-> {
    ${reusableContentBlocksProjection}
  },
  _type == "referenceToImageWithPointsDocument" => {
    ...
  }-> {
    ${imageWithPointsDocumentProjection}
  }
`;
