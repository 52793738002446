import {
  bodyProjection,
  Frontpage,
  MaterializedContentBlock,
  contentBlocksProjection,
  internalLinkProjection,
  contentBlocksGridProjection,
} from '@fagmobler/sanity';
import groq from 'groq';
import { populateBlocksWithProductUrls } from './blocks';

export type MaterializedFrontpage = Frontpage & {
  _id: string;
  _updatedAt: string;
  blocks: MaterializedContentBlock[] | null;
};

export const frontpageQuery = groq`*[
  _type == "frontpage" && 
  _id == $id
] {
    _id,
    _updatedAt,
    title,
    blocks[] {
      ${contentBlocksProjection}
    }
  }
| order(_updatedAt desc)[0]`;

export const latestFrontpageIdQuery = groq`*[
  _type == "frontpage" && 
  !(_id in path('drafts.**'))
] {
   _id,
   _updatedAt
} | order(_updatedAt desc)[0]._id`;

export const populateFrontpageWithProductUrls = async (
  frontpage: MaterializedFrontpage
) => {
  return {
    ...frontpage,
    blocks: await populateBlocksWithProductUrls(frontpage.blocks),
  };
};
