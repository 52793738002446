import groq from 'groq';
import { SanityKeyed, ArticleCard } from '../../schemaTypes';
import type {
  MaterializedInternalLink,
  MaterializedImageWithCrop,
} from '../../materializedTypes';
import {
  imageWithCropProjection,
  internalLinkProjection,
} from '../../materializedTypes';

export type MaterializedArticleCard = SanityKeyed<
  ArticleCard & {
    internalLink?: MaterializedInternalLink;
    mainImage?: MaterializedImageWithCrop;
  }
>;

export const articleCardProjection = groq`
  ...,
  internalLink {${internalLinkProjection}},
  mainImage {${imageWithCropProjection}}
`;
