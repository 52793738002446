import groq from 'groq';

import {
  contentBlockProjection,
  simpleProductCategoryEnrichmentMaterialization,
} from './blocks';
import { imageWithPointsProjection } from './materializedTypes';

// Only for references
export const contentBlocksProjection = groq`
  _type == 'reference' => @-> {
    ...,
    _type == 'reusableContentBlock' => @ {
      ...,
      contentBlocks[] {
        ${contentBlockProjection}
      }
    },
    _type == 'imageWithPointsDocument' => @ {
       ...,
       imageWithPoints {
        ${imageWithPointsProjection}
       }
    },
    _type == 'productCategoryEnrichment' => @ {
      ${simpleProductCategoryEnrichmentMaterialization}
    }
  },
  _type != 'reference' => @ {
    ${contentBlockProjection}
  }
`;
