import {
  MaterializedImageWithCrop,
  imageWithCropProjection,
} from '../../materializedTypes';
import { SanityKeyed, ImageCollection } from '../../schemaTypes';
import groq from 'groq';

export type MaterializedImageCollectionBlock = SanityKeyed<
  Omit<ImageCollection, 'images'> & {
    images: SanityKeyed<MaterializedImageWithCrop>[];
  }
>;

export const imageCollectionProjection = groq`
  ...,
  images[] {
    _key,
    ${imageWithCropProjection}
  }
`;
