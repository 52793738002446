import groq from 'groq';
import { ProductCategoryEnrichment } from '../schemaTypes';
import { MaterializedContentBlock } from '../blocks';
import { contentBlocksProjection } from '../contentBlocks';
import {
  MaterializedImageWithCrop,
  imageWithCropProjection,
} from '../materializedTypes';

export type MaterializedProductCategoryEnrichment = Omit<
  ProductCategoryEnrichment,
  'contentBlocks' | 'mainImage' | 'priorityContentBlocks'
> & {
  contentBlocks?: MaterializedContentBlock[];
  priorityContentBlocks?: MaterializedContentBlock[];
  mainImage?: MaterializedImageWithCrop;
};

export const productCategoryEnrichmentDocumentMaterialization = groq`{
  ...,
  mainImage {
    ${imageWithCropProjection}
  },
  contentBlocks[] {
    ${contentBlocksProjection}
  },
  priorityContentBlocks[] {
    ${contentBlocksProjection}
  }
}`;

export const productCategoryEnrichmentQuery = groq`
  *[_type == "productCategoryEnrichment" && lower(algoliaProductCategorySlug) == lower($algoliaProductCategory)][0]
    ${productCategoryEnrichmentDocumentMaterialization}
`;

export const productCategoryEnrichmentQueryFromOmniumId = groq`
  *[_type == "productCategoryEnrichment" && omniumId == $omniumId][0]
    ${productCategoryEnrichmentDocumentMaterialization}
`;

export const productCategoryEnrichmentPreviewQuery = groq`
  *[_type == "productCategoryEnrichment" && lower(algoliaProductCategorySlug) == lower($algoliaProductCategory) && _id == $id][0]
  ${productCategoryEnrichmentDocumentMaterialization}
`;
