import { Omnium, OmniumProductCategory } from '@fagmobler/omnium';
import { HOST } from '../host';
import { configuredSanityClientWithoutCdn } from '../sanity/sanityClient';
import {
  SimpleProductCategoryEnrichment,
  simpleProductCategoryEnrichmentMaterialization,
} from '@fagmobler/sanity';

export type getCategoriesResult = ({
  enrichment?: SimpleProductCategoryEnrichment | null;
} & OmniumProductCategory)[];

export const getCatgories = async (parent: string | undefined) => {
  if (!parent) {
    return [];
  }

  const tokenReq = await fetch(
    `${HOST}/api/omnium/token?s=${process.env.VERCEL_SECRET}`
  );
  const tokenRes = (await tokenReq.json()) as { token: string };

  const omnium = new Omnium(tokenRes.token);

  const categories = await omnium.productCategories.getSubCategories(parent);

  if (!categories) {
    console.warn('No categories found', parent);
    return [];
  }

  const categoriesIds = categories.map((category) => category.categoryId);

  const sanityCategoryEnrichments =
    await configuredSanityClientWithoutCdn.fetch<
      SimpleProductCategoryEnrichment[]
    >(
      `*[_type == "productCategoryEnrichment" && omniumId in $ids] {
        ${simpleProductCategoryEnrichmentMaterialization}
      }`,
      { ids: categoriesIds }
    );

  return (
    categories.map((category) => ({
      ...category,
      enrichment:
        sanityCategoryEnrichments.find(
          (enrichment) => enrichment.omniumId === category.categoryId
        ) || null,
    })) || []
  ).sort((a, b) =>
    a.sortIndex <= b.sortIndex ? -1 : 1
  ) as getCategoriesResult;
};
