import groq from 'groq';
import { campaignTabProjection, MaterializedCampaignTab } from './campaignTab';
import { CampaignTabs, SanityKeyed } from '../../schemaTypes';

export type MaterializedCampaignTabs = SanityKeyed<
  CampaignTabs & {
    tabs: MaterializedCampaignTab[];
  }
>;

export const campaignTabsProjection = groq`
  ...,
  "tabs" => {${campaignTabProjection}}[]
`;
