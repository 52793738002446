import groq from 'groq';
import { Page, Body } from '../schemaTypes';
import { MaterializedBody, bodyProjection } from './body';
import {
  MaterializedImageWithCrop,
  imageWithCropProjection,
} from '../materializedTypes';
import {
  MaterializedPortableText,
  portableTextProjection,
} from '../materializedTypes/portableText';
import {
  SimpleProductCategoryEnrichment,
  simpleProductCategoryEnrichmentMaterialization,
} from '../blocks';

export type MaterializedArticlePage = Page & {
  lead?: MaterializedPortableText | null;
  body: MaterializedBody | null;
  categories?: SimpleProductCategoryEnrichment[] | null;
};

export const articlePageProjection = groq`
  ...,
  lead[] {
    ${portableTextProjection}
  },
  body[] {
    ${bodyProjection}
  },
  categories[] -> {
    ${simpleProductCategoryEnrichmentMaterialization}
  }
`;

export const articlePageQuery = groq`*[_id == $id][0] {
  ${articlePageProjection}
}`;

export type SimpleMaterializedArticlePage = Pick<
  Page,
  '_id' | '_type' | 'slug' | 'title' | 'lead'
> & {
  firstParagraph: Body[0] | null;
  mainImage: MaterializedImageWithCrop | null;
};

export const simpleArticlePageProjection = groq`
  _id,
  _type,
  slug,
  title,
  lead,
  mainImage {
    ${imageWithCropProjection}
  },
  body[0]{...}
`;
